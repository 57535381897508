import Vue from 'vue'
import asyncImport from '@/utils/client/asyncImport'

export default {
  apex: () => asyncImport(import('./apex.vue')),
  chartjs: () => asyncImport(import('./chartjs.vue')),
  barGroupedByValues: () => asyncImport(import('./barGroupedByValues.vue')),
  pieGroupedByValues: () => asyncImport(import('./pieGroupedByValues.vue')),
  table: () => asyncImport(import('./table.vue')),
  mixed: () => asyncImport(import('./mixed.vue')),
  data: () => asyncImport(import('./data.vue')),
  map: () => asyncImport(import('./map.vue')),
  openlayers: () => asyncImport(import('./openLayers.vue')),
  calendar: () => asyncImport(import('./calendar.vue'))
} as Record<string, () => Vue.AsyncComponent>
