import gql from 'graphql-tag'

export const ChartUpdateFragment = gql`
  fragment ChartUpdate on Chart {
    title
    name
    chartTypeId
    collectionId
    allowsNoFilter
    filterByDefault
    filtersIds
    disableRefresh
  }
`

export const ChartCreateFragment = gql`
  fragment ChartCreate on Chart {
    _id
    environmentId
  }
`

export const ChartFragment = gql`
  fragment Chart on Chart {
    ...ChartCreate
    ...ChartUpdate
  }

  ${ChartCreateFragment}
  ${ChartUpdateFragment}
`

export const ChartEditFragment = gql`
  fragment ChartEdit on Chart {
    ...ChartUpdate
    roles
    options
    previewParameters
  }

  ${ChartUpdateFragment}
`
